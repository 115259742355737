<script setup>

import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import Editor from "primevue/editor";
import {ref, watch} from "vue";

const props = defineProps({
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const form = useForm({
    main_category: resource.main_category,
    category: resource.category,
    subject: resource.subject,
    short_name: resource.short_name,
    description: resource.description,
    email_body: resource.email_body,
    active: true,
    exit: false,
})
console.log('res', resource.email_body)
const handleInput = (id, key) => {
    form[key] = id;
}
 // Bind needed for production build
const emits = defineEmits(["update:modelValue"])

const onLoad = ({instance}) => {
    console.log('sdad', form.email_body);
    instance.setContents(instance.clipboard.convert({
        html: form.email_body
    }))
}

const onChange = (v) => {
   form.email_body = v
}
const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .put('/admin/follow_up/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/follow_up/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit email template" />
    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/follow_up">Email templates</a>
                </li>
                <li class="breadcrumb-item">
                    Edit email template
                </li>
            </ol>
        </template>

        <template v-slot:title>Email template</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">

                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add Template</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-12">
                                        <v-select
                                            label="Category"
                                            v-model="form.main_category"
                                            required
                                            placeholder="Select email category"
                                            :error-messages="form.errors.main_category"
                                            clearable
                                            :items="['Reached', 'Not Reached']"
                                        ></v-select>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-select
                                            label="Sub-category"
                                            v-model="form.category"
                                            required
                                            placeholder="Select email sub-category"
                                            :error-messages="form.errors.category"
                                            clearable
                                            :items="['Introduction', 'No Response', 'Follow-Up', 'Thank You', 'Last chance', 'Before trip', 'Post Trip', 'Reminders', 'Notifications', 'Usefull', 'Holidays', 'Canceled Trip', 'SMS & WhatsApp']"
                                        ></v-select>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Short Name"
                                            v-model="form.short_name"
                                            required
                                            placeholder="Enter short name"
                                            :error-messages="form.errors.short_name"
                                            clearable
                                        ></v-text-field>
                                    </div>

                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Description"
                                            v-model="form.description"
                                            placeholder="Enter description"
                                            :error-messages="form.errors.description"
                                            clearable
                                            required
                                        ></v-text-field>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Subject"
                                            v-model="form.subject"
                                            required
                                            placeholder="Enter subject"
                                            :error-messages="form.errors.subject"
                                            clearable
                                        ></v-text-field>
                                    </div>
                                    <div class="col-sm-12 col-12">
                                        <Editor editorStyle="height: 20em" @load="onLoad" @update:modelValue="onChange"/>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-switch label="Is Active?"
                                                  v-model="form.active"
                                        ></v-switch>
                                    </div>
                                    <div class="col-sm-12 col-12">
                                        <v-card title="Variables which we can use">
                                            <v-card-text>
                                                <table style="width: 100%;">
                                                    <thead>
                                                    <tr>
                                                        <th style="padding-left: 15px">Client:</th>
                                                        <th style="padding-left: 15px">Request:</th>
                                                        <th style="padding-left: 15px">Agent:</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <ol>
                                                                <li>$customerFirstName</li>
                                                                <li>$customerLastName</li>
                                                                <li>$customerFullName</li>
                                                                <li>$customerTimeOfTheDay</li>
                                                                <li>$lastQuotesSentDaysAgo</li>
                                                                <li>$totalQuotesSent</li>
                                                            </ol>
                                                        </td>
                                                        <td>
                                                            <ol>
                                                                <li>$departureDate</li>
                                                                <li>$returnDate</li>
                                                                <li>$departureCity</li>
                                                                <li>$returnCity</li>
                                                                <li>$numberOfAdults</li>
                                                                <li>$numberOfChildren</li>
                                                                <li>$numberOfInfants</li>
                                                            </ol>
                                                        </td>
                                                        <td>
                                                            <ol>
                                                                <li>$agentFirstName</li>
                                                                <li>$agentLastName</li>
                                                                <li>$agentFullName</li>
                                                                <li>$agentEmail</li>
                                                                <li>$agentPhone</li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </v-card-text>
                                        </v-card>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete Template</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style scoped>
.two-columns {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}

table {
    width: 100%;
    border-collapse: collapse; /* Убирает лишние отступы между ячейками таблицы */
}

td, th {
    padding: 5px; /* Унифицированный отступ для заголовков и данных */
    vertical-align: top; /* Выравнивание текста по верхней части ячейки */
}

ol {
    margin: 0; /* Убирает внешние отступы */
    padding-left: 20px; /* Минимальный внутренний отступ для списков */
}
</style>
